<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="col-12 auth-logo">
          <img src="@/assets/images/logo-full.png" />
        </div>
        <div class="account-pages my-5 pt-5">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="text-center mb-5">
                  <h1 class="display-2 font-weight-medium">
                    4<i class="bx bx-buoy bx-spin text-primary display-3"></i>4
                  </h1>
                  <h4 class="text-uppercase">Sorry, page not found</h4>
                  <div class="mt-5 text-center">
                    <router-link tag="a" class="btn btn-primary" to="/"
                      >Back home</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "404"
};
</script>
